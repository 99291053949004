import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import monkey from '../../../../Images/monkey3.png';
import './Table.css';
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('0xC31afe50', '0xC31afe50', '', 'Expires in 23h', 'view Deal')
  //   createData('Ice cream sandwich', 237, 9.0, 37),
  //   createData('Eclair', 262, 16.0, 24),
  //   createData('Cupcake', 305, 3.7, 67)
  //   createData('Gingerbread', 356, 16.0, 49, 3.9)
];

export default function BasicTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead style={{ backgroundColor: '#A03939', color: 'white' }}>
          <TableRow>
            <TableCell>MAKER</TableCell>
            <TableCell align="left">TAKER</TableCell>
            <TableCell align="left">STATUS</TableCell>
            <TableCell align="left">TIMING</TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              style={{ backgroundColor: '#7B7B7B', cursor: 'pointer' }}
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
                <br></br>
                <img src={monkey} />
              </TableCell>
              <TableCell align="left">{row.calories}</TableCell>
              <TableCell align="left">{row.fat}</TableCell>
              <TableCell align="left">{row.carbs}</TableCell>
              <TableCell align="left">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

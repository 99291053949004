import "./CollectionMenu.css";
import React , {Component } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Menu from "@material-ui/core/Menu";
import { Grid, makeStyles } from "@material-ui/core";
import Modal from "../Modal/Modal";
import { useHistory } from "react-router-dom";
import { MenuItem } from "@material-ui/core";

const CollectionMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  const history = useHistory();
  
  function handleCollections() {
    history.push(`/Collections`);
  }
  function handleClose() {
    setAnchorEl(null);
  }

  const [open, setOpen] = React.useState(false);
  const [videoURL, setVideoURL] = React.useState();

  const apeIslandVideo =
    "https://www.youtube.com/watch?v=ns_ve_-4owQ&ab_channel=NiftyApeNation";
  const apeMAchineVideo =
    "https://www.youtube.com/watch?v=h1uNcazWFwY&ab_channel=NiftyApeNation";
  const apeFightClubVideo = "https://www.youtube.com/watch?v=PUq0KUhm-w8";

  return (
    <>
      <div>
        <div
          className="newcolor"
          onMouseOver={handleClick}
          onClick={handleCollections}
        >
          <AnchorLink>
            {" "}
            <b>The Collection</b>
          </AnchorLink>
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{ onMouseLeave: handleClose }}
          style={{ marginTop: "58px" }}
          // className={classes.root}
        >
          <div>
            <MenuItem onClick={() => {
                window.open("https://opensea.io/collection/nifty-ape-nation", "_blank");
              }}>
              <AnchorLink className="dropd" href="">OpenSea</AnchorLink>
            </MenuItem>

            <MenuItem onClick={() => {
                window.open("https://looksrare.org/collections/0xaef8B6346CA4DaDaA71783dDdF4a3D00633B679d", "_blank");
              }}>
              <AnchorLink className="dropd" href="">LOOKSRARE</AnchorLink>
            </MenuItem>

            <MenuItem onClick={() => {
                window.open("https://rarible.com/nifty-ape-nation", "_blank");
              }}>
              <AnchorLink className="dropd" href="">Rarible</AnchorLink>
            </MenuItem>
            {/* <MenuItem
              onClick={() => {
                setVideoURL(apeIslandVideo);
                setOpen(true);
              }}
            >
              <AnchorLink className="dropd">Ape Island</AnchorLink>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setVideoURL(apeMAchineVideo);
                setOpen(true);
              }}
            >
              <AnchorLink className="dropd">Ape Machine</AnchorLink>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setVideoURL(apeFightClubVideo);
                setOpen(true);
              }}
            >
              <AnchorLink className="dropd">Ape Fight Club</AnchorLink>
            </MenuItem> */}
          </div>
        </Menu>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Modal open={open} videoURL={videoURL} setOpen={setOpen} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};;
export default CollectionMenu;

import React from "react";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import {
  Container,
  Grid,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";
import monkey from "../../../Images/monkey1.png";
import GIF from "../../../Images/ezgif.com-gif-maker.gif";
import "./Avatars.css";
export default function Avatars({ avatarData }) {
  const history = useHistory();

  function meetApes() {
    history.push(`/Collections`);
  }

  return (
    <>
      <Container className="attributes_main ">
        <Grid contaienr justify="center">
          <Typography className="ava_typo1">{avatarData?.title}</Typography>
        </Grid>
        <Grid container justify="space-between" className="avatars_main">
          <Grid item xs={12} md={6} lg={6}>
            <div>
              <Typography className="ava_typo1">
                {avatarData?.subTitle}
              </Typography>
            </div>
            {avatarData?.description.map((data) => (
              <div>
                <Typography className="ava_typo2">
                  <span className="ava_sub_typo2">{data.heading}</span>{" "}
                  <br></br>
                  {data.description}
                </Typography>
              </div>
            ))}
          </Grid>
          <Grid item container xs={12} md={5} lg={5} className="big_ava_grid">
            <Grid className="big_avatar">
              <img className="big_ava_img" src={GIF} alt="monkey" />
            </Grid>
            <Grid>
              <Button className="big_ava_btn" onClick={meetApes}>
                Meet The Apes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

import React, { useState } from "react";
import {
  Button,
  Container,
  Grid,
  Input,
  TextField,
  Typography,
} from "@material-ui/core";
import "./Gallery.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Card from "../../HomePage/Attributes/AttributeCard/Card";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Pagination from "@material-ui/lab/Pagination";
import Modal from "../../Modal/Modal";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Gallery({
  attCards,
  collections,
  handleChangeData,
  setLoader,
  loader,
}) {
  const [slickData, setSlickData] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleOpen = (data) => {
    console.log("data:", data);
    setSlickData(data);
    setOpen(true);
  };
  return (
    <Container className="margin_top">
      <Grid container justify="center">
        <Grid item xs={12}>
          <Typography className="gall_typo">Gallery</Typography>
        </Grid>

        {/* <div>
          <TextField
            className="input"
            fullWidth
            placeholder="Find your ape"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className="search_icon" />
                </InputAdornment>
              ),
            }}
          ></TextField>
        </div> */}
      </Grid>

      <Grid container justify="center">
        {/* <Grid item xs={12} md={2} lg={2} className="filters">
          <Typography className="filter_sub">Filters</Typography>
          <div class="dropdown">
            <div className="filter_sub_div">
              <Typography className="filter_sub2">Gender</Typography>
              <KeyboardArrowDownIcon className="down_arrow_f" />
            </div>
            <div class="dropdown-content">
              <a href="#">Male</a>
              <a href="#">Female </a>
              <a href="#">Other</a>
            </div>
          </div>
          <div class="dropdown">
            <div className="filter_sub_div">
              <Typography className="filter_sub2">Body Type</Typography>
              <KeyboardArrowDownIcon className="down_arrow_f" />
            </div>
            <div class="dropdown-content">
              <a href="#">Skinny</a>
              <a href="#">Muscular </a>
              <a href="#">Fat</a>
            </div>
          </div>
          <div class="dropdown">
            <div className="filter_sub_div">
              <Typography className="filter_sub2">Type Of Ape</Typography>
              <KeyboardArrowDownIcon className="down_arrow_f" />
            </div>
            <div class="dropdown-content">
              <a href="#">Skinny</a>
              <a href="#">Muscular </a>
              <a href="#">Fat</a>
            </div>
          </div>
          <div class="dropdown">
            <div className="filter_sub_div">
              <Typography className="filter_sub2">Outfit</Typography>
              <KeyboardArrowDownIcon className="down_arrow_f" />
            </div>
            <div class="dropdown-content">
              <a href="#">Formal</a>
              <a href="#">Casual </a>
              <a href="#">Funky</a>
            </div>
          </div>
          <div class="dropdown">
            <div className="filter_sub_div">
              <Typography className="filter_sub2">Props</Typography>
              <KeyboardArrowDownIcon className="down_arrow_f" />
            </div>
            <div class="dropdown-content">
              <a href="#">Formal</a>
              <a href="#">Casual </a>
              <a href="#">Funky</a>
            </div>
          </div>
          <div class="dropdown">
            <div className="filter_sub_div">
              <Typography className="filter_sub2">Background</Typography>
              <KeyboardArrowDownIcon className="down_arrow_f" />
            </div>
            <div class="dropdown-content">
              <a href="#">Black</a>
              <a href="#">Blue </a>
              <a href="#">Green</a>
            </div>
          </div>
          <div class="dropdown">
            <div className="filter_sub_div">
              <Typography className="filter_sub2">Pose</Typography>
              <KeyboardArrowDownIcon className="down_arrow_f" />
            </div>
            <div class="dropdown-content">
              <a href="#">T-pose</a>
              <a href="#">Basic pose </a>
              <a href="#">Legendary</a>
            </div>
          </div>
          <div class="dropdown">
            <div className="filter_sub_div">
              <Typography className="filter_sub2">Eye color</Typography>
              <KeyboardArrowDownIcon className="down_arrow_f" />
            </div>
            <div class="dropdown-content">
              <a href="#">Red</a>
              <a href="#">Blue </a>
              <a href="#">Green</a>
            </div>
          </div>
          <div className="filter_sub_div">
            <Button fullWidth className="reset_btn">
              Reset
            </Button>
          </div>
        </Grid> */}

        <Grid container justifyContent="center" xs={12} spacing={2}>
          {/* {attCards?.map((i) => (
            <Grid
              item
              style={{ marginTop: "20px", cursor: "pointer" }}
              onClick={() => handleOpen(i)}
            >
              <Card attCards={i} />
            </Grid>
          ))} */}
          {loader ? (
            <div
              style={{
                width: "100%",
                marginTop: "150px",
                display: "flex",
                marginBottom: "150px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress style={{ color: "black" }} />
            </div>
          ) : (
            collections.data?.map((apeDate) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                style={{ marginTop: "20px", cursor: "pointer" }}
                onClick={() => handleOpen(apeDate)}
              >
                <Card attCards={apeDate} />
              </Grid>
            ))
          )}
        </Grid>
        <div style={{ marginTop: "20px" }}>
          <Pagination
            count={5}
            // count={Math.ceil(collections.total / 6)}
            onChange={handleChangeData}
            style={{ marginTop: "25px", color: "#711f1e" }}
          />
        </div>
      </Grid>
      <Modal open={open} slickData={slickData} setOpen={setOpen} />
    </Container>
  );
}

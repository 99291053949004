import React from "react";
import "./TeamCard.css";

import i1 from "../../../../Images/i1.png";
import i2 from "../../../../Images/i2.png";
import i3 from "../../../../Images/i3.png";
import i4 from "../../../../Images/i4.png";
import i5 from "../../../../Images/i5.png";
import i6 from "../../../../Images/i6.png";
import i7 from "../../../../Images/i7.png";

export default function TeamCard({ background, icons }) {
  return (
    <div class="gallery">
      <a
        class="item flip-card"
        // href="https://codepen.io/agn35"
        target="_blank"
        target="_blank"
      >
        <div class="flip-card-inner">
          <div class="flip-text">
            <div
              className="divtxt_padd"
              style={{ padding: "30px", lineHeight: "40px" }}
            >
              {background.number}
              {background.desciption}
              <b>Name:</b>
              <br />
              <span className="light-text">{background.name}</span>{" "}
              <div>
                <b>Twitter Handle:</b>
                <br />
                <span
                  className="light-text-blue"
                  onClick={() => {
                    window.open(
                      `https://twitter.com/${background.twitterHandle}`
                    );
                  }}
                >
                  {background.twitterHandle}
                </span>
              </div>
              <div>
                <b>Role:</b>
                <br />
                <span className="light-text"> {background.role}</span>{" "}
              </div>
              <div
                className="iconss_divv"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // alignContent: 'space-between'
                  marginTop: "74%",
                }}
              >
                {/* {icons.map((icon) => ( */}
                {background.discordLink ? (
                  <div>
                    <img src={i1} alt="logo" className="team_icons" />
                  </div>
                ) : null}
                {background.instagramLink ? (
                  <div>
                    <img src={i2} alt="logo" className="team_icons" />
                  </div>
                ) : null}
                {background.redditLink ? (
                  <div>
                    <img src={i3} alt="logo" className="team_icons" />
                  </div>
                ) : null}
                {background.tiktokLink ? (
                  <div>
                    <img src={i4} alt="logo" className="team_icons" />
                  </div>
                ) : null}
                {background.twitterLink ? (
                  <div>
                    <img src={i5} alt="logo" className="team_icons" />
                  </div>
                ) : null}
                {background.twitchLink ? (
                  <div>
                    <img src={i6} alt="logo" className="team_icons" />
                  </div>
                ) : null}
                {background.youtubeLink ? (
                  <div>
                    <img src={i7} alt="logo" className="team_icons" />
                  </div>
                ) : null}

                {/* ))} */}
              </div>
            </div>
          </div>
          <div class="flip-img">
            <div
              class="img-flex"
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_AWS_URL + background.image
                })`,
              }}
            ></div>
          </div>
        </div>
      </a>
    </div>
  );
}

import React from "react";
import "./Roadmap.css";
import { Grid, Typography} from "@material-ui/core";



export default function Roadmap({ roadmapData }) {

  return(
    <>
      <div className="card">
      <Typography className="road_ypo" style={{ marginTop: "20px" }}>
        Roadmap
      </Typography>
      <Grid container className="grid-media-left" >
          <Grid item>
            <div  className="mainroad">
            {roadmapData?.map((data) => {
              console.log("data", data);
              return data.roadMap.roadmapType === "Releases" ?(
                <div className="ns">
                  <Typography className="road_typo3">
                    {data.roadMap.date}
                  </Typography>
                  <Typography className="road_typo4">
                    {data.roadMap.title}
                  </Typography>
                  <Typography className="road_typo3">
                    {" "}
                    {data.roadMap.subTitle}{" "}
                  </Typography>
                </div>
              ) : null;
            })}

            {roadmapData?.map((data) => {
              console.log("data", data);
              return data.roadMap.roadmapType === "Experiences" ? (
                <div className="ns">
                  <Typography className="road_typo6">
                    {data.roadMap.date}
                  </Typography>
                  <Typography className="road_typo7">
                    {data.roadMap.title}
                  </Typography>
                  <Typography className="road_typo8">
                    {data.roadMap.event}
                  </Typography>
                  <Typography className="road_typo6">
                    {" "}
                    {data.roadMap.subTitle}{" "}
                  </Typography>
                </div>
              ) : null;
            })}
            </div>
          </Grid>
          </Grid>
      </div>
    </>
  )
}







import React from 'react';
import { Container, Grid, Typography, Divider } from '@material-ui/core';
import './Header.css';

export default function Header() {
  return (
    <Container className="margin_top">
      <Grid container justify="center">
        <Grid item>
          <Typography xs={12} className="head_typo">
            Nifty Ape Nation Collection
          </Typography>
        </Grid>
      </Grid>
      <Divider className="divider2" />
    </Container>
  );
}

import React, { useState } from "react";
import { Container, Grid, Divider, Typography } from "@material-ui/core";
import left from "../../../Images/left.png";
import right from "../../../Images/right.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Section2.css";
import Modal from "./Modal/Modal";
import CountDown from "../../CountDown/CountDown";
export default function Section2({ cards, toaData }) {
  const [slickData, setSlickData] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleOpen = (data) => {
    console.log("data:", data);
    setSlickData(data);
    setOpen(true);
  };

  console.log("toaData", toaData);

  const settings = {
    dots: false,
    infinite: true,
    lazyLoad: true,
    autoplay: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,

    nextArrow: <img src={right} />,
    prevArrow: <img src={left} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Container>
      <Grid item xs={12} md={12} lg={12} className="section2_main">
        <div style={{ position: "relative" }}>
          <Slider {...settings}>
            {toaData?.map((i) => (
              <>
                <div onClick={() => handleOpen(i)} className="testImage">
                  {" "}
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={process.env.REACT_APP_AWS_URL + i.image}
                      className="slickImg"
                      width="96%"
                    />
                    <div
                      style={{
                        bottom: 0,
                        position: "absolute",
                        textAlign: "center",
                        color: "white",
                        left: "0",
                        right: "0",
                        padding: " 0px 6px ",
                      }}
                    >
                      <div className="toa_text">
                        <span style={{ fontSize: "20px", fontWeight: 600 }}>
                          {i.title}
                        </span>
                        <p
                          style={{
                            fontStyle: "italic",
                            textDecoration: "underline",
                          }}
                        >
                          See the Ape Type Utility
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </Slider>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Grid
              className="counter_left"
              xs={6}
              item
              style={{ position: "absolute", top: "35%" }}
            >
              {/* <CountDown /> */}
            </Grid>
          </div>

          <Divider className="divider" />
        </div>
      </Grid>
      <Modal open={open} slickData={slickData} setOpen={setOpen} />
    </Container>
  );
}

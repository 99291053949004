import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from './Pages/HomePage/HomePage';
import Collections from './Pages/CollectionPage/Collections';
import './App.css';
function App() {
  return (
    <>
      <Router> 
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/Collections" component={Collections} />
        </Switch>
      </Router>
    </>
  );
}

export default App;

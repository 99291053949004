import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import Modal from "./Modal/Modal";
import { Grid } from "@material-ui/core";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Dropdown from "./Dropdown/Dropdown";
import Popup from "./Popup/Popup";
import CollectionMenu from "./CollectionMenu/CollectionMenu";
import NavBarLogo from "../../Images/logo.png";
import i5 from "../../Images/i5.png";
import i2 from "../../Images/i2.png";
import i7 from "../../Images/i7.png";

import "./PrimaryNavBar.css";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: "56%",
    left: "49%",
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  grow: {
    // flexGrow: 1,
  },
  title: {
    display: "none",
    color: "black",
    [theme.breakpoints.up("xs")]: {
      display: "block",
      color: "black",
      cursor: "pointer",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#EEEEE9",
    "&:hover": {
      backgroundColor: "#EEEEE9",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    color: "black",
    
    

    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  navbar1: {
    padding: "10px",
    cursor: "pointer",
  },
  paper: {
    position: "relative",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: "0 0 4px",
    borderRadius: "10px",
    padding: theme.spacing(2, 4, 3),
  },
  drop: {
backgroundColor:"#3b3b3b",
  },
}));


export default function PrimaryNavBar() {
  const history = useHistory();

  const scrollToId = window.location.href.split("/")[4];

  console.log("scrollToId", scrollToId);

  function handleNavigate() {
    console.log("in handle naviagte");
    history.push(`/`);
  }
  if (scrollToId) {
    setTimeout(() => {
      console.log(`/${scrollToId}`);

      const pageScroll = document.getElementById(`/${scrollToId}`);
      pageScroll.scrollIntoView({ behavior: "smooth" });
    }, 0);
    setTimeout(() => {
      console.log("in timeout naviagte");
      handleNavigate();
    }, 1000);
  }
  function handleCollections() {
    history.push(`/Collections`);
  }
  function handleMemberBenefits() {
    history.push(`/#/MemberBenefits`);
  }
  function handleRoadmap() {
    history.push(`/#/Roadmap`);
  }
  function handleAttributes() {
    history.push(`/#/Attributes`);
  }

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [open, setOpen] = React.useState(false);
  const [videoURL, setVideoURL] = React.useState();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const apeIslandVideo =
    "https://www.youtube.com/watch?v=ns_ve_-4owQ&ab_channel=NiftyApeNation";
  const apeMAchineVideo =
    "https://www.youtube.com/watch?v=h1uNcazWFwY&ab_channel=NiftyApeNation";
  const apeFightClubVideo = "https://www.youtube.com/watch?v=PUq0KUhm-w8";

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      
    >
      <MenuItem onClick={handleRoadmap}>
        <AnchorLink className="anchor" href="#/Roadmap">
         <b>  Roadmap</b>
        </AnchorLink>
      </MenuItem>
      <MenuItem > 
       <Popup />
      </MenuItem>
      <MenuItem>
      
       <CollectionMenu />
      </MenuItem>
      <MenuItem onClick={handleAttributes}>
        <AnchorLink className="anchor" href="#/Attributes">
          <b> Team</b>
        </AnchorLink>
      </MenuItem>
    </Menu>
  );
  
  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div className={classes.grow}>
      {/* <Container> */}
      <AppBar
        position="static"
        color="white"
        style={{ boxShadow: "none", backgroundColor: "transparent" }}
      >
        <Toolbar style={{ justifyContent: "space-between", height: "auto" }}>
          <img
            className="logo"
            style={{ cursor: "pointer" }}
            src={NavBarLogo}
            // height="36px"
            onClick={handleNavigate}
          />

          <Grid
            className={classes.sectionDesktop} style={{ alignItems: "center" }}
          
          // style={{ marginRight: '8%' }}
          >
            <div className="navbar1">
              <h4 className="tt">
                {" "}
                <a className="anchor" onClick={handleRoadmap}>
                <b>  Roadmap </b>
                </a>
              </h4>
            </div>
            <div className="navbar1">
              <h4 className="tt">
              <Popup />
              </h4>
            </div>
            <div className="navbar1">
              <h4 className="tt">
                <CollectionMenu />
              </h4>
            </div>
            <div className="navbar1" onClick={handleAttributes}>
              <h4 className="tt">
                {" "}
                <AnchorLink className="anchor" href="#/Attributes">
                  <b>Team </b>
                </AnchorLink>
              </h4>
            </div>
            <div class="header_bar">
              <div class="icon_w">
                <a class="image-2" onClick={() => {
                    window.open("https://twitter.com/earthpolitan", "_blank");}}>
                  <img src={i5} loading="lazy" alt="" class="image-2"/></a>
                  </div>
              <div class="icon_w">
                <a class="image-2" onClick={() => {
                    window.open("https://www.instagram.com/NiftyApeNation/", "_blank");}}>
                <img src={i2} loading="lazy" alt="" class="image-2"/></a>
                </div>
              <div class="icon_w">
              <a class="image-2" onClick={() => {
                    window.open("https://www.youtube.com/c/niftyapenation/videos", "_blank");}}>
                <img src={i7} loading="lazy" alt="" class="image-2"/></a>
                </div>
            </div>
          </Grid>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon style={{ color: "white", fontSize: "40" }} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {/* </Container> */}
      <Grid container justify="center">
        <Grid item xs={12}>
          <Modal open={open} videoURL={videoURL} setOpen={setOpen} />
        </Grid>
      </Grid>
    </div>
  );
}

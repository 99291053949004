import React from "react";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import ReactPlayer from "react-player";

import i1 from "../../../Images/i1.png";
import i2 from "../../../Images/i2.png";
import i3 from "../../../Images/i3.png";
import i4 from "../../../Images/i4.png";
import i5 from "../../../Images/i5.png";
import i6 from "../../../Images/i6.png";
import i7 from "../../../Images/i7.png";

import "./main1.css";
export default function Main1({ icons, carouselData }) {
  return (
    <Container>
      <Grid item container justify="space-between" className="section1_bg">
        <Grid
          item
          container
          justify="center"
          xs={12}
          md={12}
          lg={6}
          className="section1_g1"
        >
          <Grid item xs={12}>
            <Typography className="section1_typo1">
              {carouselData?.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="section1_typo2">
              {carouselData?.subtitle}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              onClick={() => {
                window.open("https://mint.niftyapenation.com/", "_blank");
              }}
              className="section1_btn"
            >
              MINT YOUR APE NOW
            </Button>
          </Grid>
          {/* <Grid item xs={12}>
            <Button
              onClick={() => {
                window.open(
                  "https://ct5nz8sh0r5.typeform.com/to/wnMv3szh",
                  "_blank"
                );
              }}
              className="section1_btn"
            >
              Join Shortlist
            </Button>
          </Grid> */}
        </Grid>
        <Grid item xs={11} md={12} lg={6}>
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url={carouselData?.carouselVideoLink}
              width="100%"
              height="100%"
            />
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        // justify="flex-end"
        xs={12}
        spacing={1}
        className="icons_grid"
      >
        {/* {icons.map((icon) => (
          <Grid item className="icons">
            <img src={icon} alt="logo" className="icons_icons" />
          </Grid>
        ))} */}

        {carouselData?.discordLink ? (
          <Grid item className="icons">
            <img
              onClick={() => window.open(carouselData.discordLink, "_blank")}
              src={i1}
              alt="logo"
              className="icons_icons"
            />
          </Grid>
        ) : null}
          {carouselData?.twitterLink ? (
          <Grid item className="icons">
            <img
              onClick={() => window.open(carouselData.twitterLink, "_blank")}
              src={i5}
              alt="logo"
              className="icons_icons"
            />
          </Grid>
        ) : null}
        {carouselData?.instagramLink ? (
          <Grid item className="icons">
            <img
              onClick={() => window.open(carouselData.instagramLink, "_blank")}
              src={i2}
              alt="logo"
              className="icons_icons"
            />
          </Grid>
        ) : null}
        {carouselData?.redditLink ? (
          <Grid item className="icons">
            <img
              onClick={() => window.open(carouselData.redditLink, "_blank")}
              src={i3}
              alt="logo"
              className="icons_icons"
            />
          </Grid>
        ) : null}
        {carouselData?.tiktokLink ? (
          <Grid item className="icons">
            <img
              onClick={() => window.open(carouselData.tiktokLink, "_blank")}
              src={i4}
              alt="logo"
              className="icons_icons"
            />
          </Grid>
        ) : null}
      
        {carouselData?.twitchLink ? (
          <Grid item className="icons">
            <img
              onClick={() => window.open(carouselData.twitchLink, "_blank")}
              src={i6}
              alt="logo"
              className="icons_icons"
            />
          </Grid>
        ) : null}
        {carouselData?.youtubeLink ? (
          <Grid item className="icons">
            <img
              onClick={() => window.open(carouselData.youtubeLink, "_blank")}
              src={i7}
              alt="logo"
              className="icons_icons"
            />
          </Grid>
        ) : null}
      </Grid>
    </Container>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import PrimaryNavBar from "../../Components/NavBar/NavBar";
import Header from "../../Components/Collections/Header/Header";
import MeetApes from "../../Components/Collections/MeetApes/MeetApes";
import Gallery from "../../Components/Collections/Gallery/Gallery";
import Footer from "../../Components/Footer/Footer";
import ape1 from "../../Images/ape1.png";
import ape2 from "../../Images/ape2.png";
import ape3 from "../../Images/ape3.png";
import ape4 from "../../Images/ape4.png";
import ape5 from "../../Images/ape5.png";
import insta from "../../Images/insta.png";
import twitter from "../../Images/twitter.png";
import twitch from "../../Images/twitch.png";
import youtube from "../../Images/youtube.png";
import discord from "../../Images/discord.png";
import card1 from "../../Images/c1.png";
import card2 from "../../Images/c2.png";
import card3 from "../../Images/c3.png";
import card4 from "../../Images/c4.png";
import i1 from "../../Images/i1.png";
import i2 from "../../Images/i2.png";
import i3 from "../../Images/i3.png";
import i4 from "../../Images/i4.png";
import i5 from "../../Images/i5.png";
import i6 from "../../Images/i6.png";
import i7 from "../../Images/i7.png";
import { Container, Divider } from "@material-ui/core";




export default function Collections() {
  const [collections, setCollections] = useState({});
  const [toa, setToa] = useState();
  const [textData, setTextData] = useState();
  const [loader, setLoader] = useState(false);
  const [carouselData, setCarouselData] = useState(false);

  React.useEffect(async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/get-content`)
      .then((res) => {
        console.log("res.data", res.data.collectionData[0]);
        setTextData(res.data?.collectionData[0]);
        setToa(res.data?.toaData);
        setCarouselData(res.data?.carouselData[0]);
      })
      .catch((err) => console.log("error", err));
    window.scrollTo(0, 0);
    let total;
    await axios
      .get(
       // https://api.opensea.io/api/v1/assets?order_direction=desc&&offset=0&limit=50&collection=nifty-ape-nation
        "https://api.opensea.io/api/v1/assets?collection=nifty-ape-nation"
      )
      .then((res) => {
        total = res.data.assets.length;
      })
      .catch((e) => console.log("error", e));

    await axios
      .get(
        "https://api.opensea.io/api/v1/assets?order_direction=desc&&offset=0&limit=6&collection=nifty-ape-nation"
      )
      .then((res) => {
        console.log("collections in reslkasjd", collections);
        setCollections({
          total,
          data: res.data.assets,
        });
      })
      .catch((e) => console.log("error", e));
  }, []);

  const handleChangeData = async (e, val) => {
    setLoader(true);

    let offset = (val - 1) * 6;
    offset = offset - 1;

    if (offset < 0) offset = 0;

    await axios
      .get(
        `https://api.opensea.io/api/v1/assets?order_direction=desc&&offset=${offset}&limit=6&collection=nifty-ape-nation`
      )
      .then((res) => {
        console.log("collections in reslkasjd", collections);
        setCollections({
          ...collections,
          data: res.data.assets,
        });
        setLoader(false);
      })
      .catch((e) => console.log("error", e));
  };

  const icons = [i1, i2, i3, i4, i5, i6, i7];

  const attCardsMeet = [
    {
      image_url: card1,
      name: "MYTHIC OG SILVERBACKS",
      des: "APE WORLD LEADERS",
      link: "https://dementclothing.com",
      id: "1005",
      count: "200",

      price: "2.9",
    },
    {
      image_url: card2,
      name: "OG  SILVERBACKS",
      des: "GUARDIANS OF THE APE NATION",
      link: "https://www.euphorya.co",
      id: "1004",
      count: "200",
      price: "0.5",
    },
    {
      image_url: card3,
      name: "SILVERBACKS",
      des: "LEADERS IN THE TRENCHES",
      link: "https://dementclothing.com",
      id: "1002",
      count: "250",
      price: "2.3",
    },
  ];

  ////

  const attCards = [
    {
      img: card1,
      title: "MYTHIC OG SILVERBACKS",
      des: "APE WORLD LEADERS",
      link: "https://dementclothing.com",
      hash: "#1005",
      count: "200",

      price: "2.9",
    },
    {
      img: card2,
      title: "OG  SILVERBACKS",
      des: "GUARDIANS OF THE APE NATION",
      link: "https://www.euphorya.co",
      hash: "#1004",
      count: "200",
      price: "0.5",
    },
    {
      img: card3,
      title: "SILVERBACKS",
      des: "LEADERS IN THE TRENCHES",
      link: "https://dementclothing.com",
      hash: "#1002",
      count: "250",
      price: "2.3",
    },
    {
      img: card4,
      title: "APE ARMY",
      des: "THE COLLECTIVE APE BRAIN",
      link: "https://dementclothing.com",
      hash: "#1003",
      count: "100",
      price: "1.8",
    },
    {
      img: card1,
      title: "TRADER APE",
      des: "TRADING STRATEGIES",
      link: "https://dementclothing.com",
      hash: "#1006",
      count: "55",
      price: "1.5",
    },
    {
      img: card1,
      title: "TRADER APE",
      des: "TRADING STRATEGIES",
      link: "https://dementclothing.com",
      hash: "#1006",
      count: "55",
      price: "1.5",
    },
  ];

  const apeCard = [
    {
      img: ape1,
      title: "MYTHIC OG SILVERBACKS",
      des: "APE WORLD LEADERS",
      link: "https://dementclothing.com",
      hash: "#1001",
      count: "55",
      price: "2.2",
    },
    {
      img: ape3,
      title: "OG  SILVERBACKS",
      des: "GUARDIANS OF THE APE NATION",
      link: "https://www.euphorya.co",
      hash: "#1002",
      count: "85",
      price: "3.2",
    },
    {
      img: ape4,
      title: "SILVERBACKS",
      des: "LEADERS IN THE TRENCHES",
      link: "https://dementclothing.com",
      hash: "#1003",
      count: "110",
      price: "2.9",
    },
    {
      img: ape2,
      title: "APE ARMY",
      des: "THE COLLECTIVE APE BRAIN",
      link: "https://dementclothing.com",
      hash: "#1004",
      count: "75",
      price: "1.5",
    },
    {
      img: ape5,
      title: "TRADER APE",
      des: "TRADING STRATEGIES",
      link: "https://dementclothing.com",
      hash: "#1005",
      count: "250",
      price: "1.8",
    },
  ];
  

  return (
    <div>
      <Container className="container_width">
        <PrimaryNavBar />

        {/* <Container style={{ width: '90%' }}> */}

        <Header />

        <div className="attributes_main2">
          <MeetApes
            toa={toa}
            textData={textData}
            apeCard={apeCard}
            attCards={attCardsMeet}
          />
        </div>
        <div>
          <Divider className="divider" />
        </div>
        <div id="/Gallery">    
         <Gallery
          attCards={attCards}
          collections={collections}
          handleChangeData={handleChangeData}
          setLoader={setLoader}
          loader={loader}
        /> 
        </div>
      </Container>
      <Footer icons={icons} carouselData={carouselData} />
    </div>
  );
}

import React from "react";
import "./ApeCard.css";
import { Container, Grid, Typography } from "@material-ui/core";
import ape1 from "../../../../Images/ape1.png";
export default function ApeCard({ toa }) {
  console.log("toa", toa);

  const { image, title, subtitle, desciption } = toa;

  return (
    <Grid item xs={12} className="ape_card">
      <Grid item>
        {" "}
        <div
          className="toa-image"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_AWS_URL + image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="ape_img" />
        </div>
      </Grid>
      <Grid item>
        {" "}
        <Typography className="ape_card_typo">{title}</Typography>
      </Grid>
      <Grid item>
        {" "}
        <Typography className="ape_card_typo2">{subtitle}</Typography>
      </Grid>
      <Grid item>
        {" "}
        <Typography className="ape_link">{desciption}</Typography>
      </Grid>
    </Grid>
  );
}

import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import monkey2 from "../../../../Images/monkey2.png";
import "./Card.css";
export default function Card({ attCards }) {
  // console.log("attCards", attCards);
  return (
    <Grid item className="att_card">
      <div>
        <img
          src={attCards?.image_url}
          width="100%"
          alt="card_img"
          style={{ borderRadius: "10px" }}
        />
      </div>
      <Typography className="card_typo">
        {attCards?.name}
        <br></br> #{attCards?.id}
      </Typography>
    </Grid>
  );
}

import React from "react";
import { Grid, Typography } from "@material-ui/core";
import dnaHead from "../../Images/dnaHead.png";
// import DNABlock from '../../Images/f5.png';
import DNABlock from "../../Images/logo.png";
import AnchorLink from "react-anchor-link-smooth-scroll";

import i1 from "../../Images/i1.png";
import i2 from "../../Images/i2.png";
import i3 from "../../Images/i3.png";
import i4 from "../../Images/i4.png";
import i5 from "../../Images/i5.png";
import i6 from "../../Images/i6.png";
import i7 from "../../Images/i7.png";
import Modal from "./Modal/Modal";
import TermAndConditionModal from "./Modal/TermAndConditionModel";
import { useHistory } from "react-router-dom";

import "./Footer.css";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: "56%",
    left: "49%",
    transform: `translate(-${top}%, -${left}%)`,
  };
}


export default function Footer({ icons, carouselData }) {
  const history = useHistory();


  const [open, setOpen] = React.useState(false);
  const [Topen, setTOpen] = React.useState(false);
  const [videoURL, setVideoURL] = React.useState();

  function handleClick() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const apeIslandVideo =
    "https://www.youtube.com/watch?v=ns_ve_-4owQ&ab_channel=NiftyApeNation";
  const apeMAchineVideo =
    "https://www.youtube.com/watch?v=h1uNcazWFwY&ab_channel=NiftyApeNation";
  const apeFightClubVideo = "https://www.youtube.com/watch?v=PUq0KUhm-w8";
  function handleCollections() {
    history.push(`/Collections`);
  }
  function handleMemberBenefits() {
    history.push(`/#/MemberBenefits`);
  }
  function handleRoadmap() {
    history.push(`/#/Roadmap`);
  }
  function handleAttributes() {
    history.push(`/#/Attributes`);
  }
  return (
    <Grid
      container
      alignItems="center"
      justify="space-around"
      className="foot_main"
    >
      <Grid item container xs={12} md={3} lg={3} justify="center" style={{display:"flex"}}>
        <Grid item xs={12} className="dnaa_img_wrap">
          <img src={DNABlock} className="dnaa_imgg" onClick={handleClick} />
        </Grid>
        <Grid item xs={12}>
          <Typography style={{cursor:"pointer"}} className="terms2" onClick={() => setTOpen(true)}>
            <div>
              Nifty Ape Nation<br></br> Terms and Conditions
            </div>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justify="center"
        xs={12}
        md={5}
        lg={5}
        className="footer_icons"
      >
        <Grid item container justify="center">
          {/* {icons.map((icon) => (
            <img src={icon} alt="logo" className="f_icons" />
          ))} */}
          {carouselData?.discordLink ? (
            <Grid item className="icons">
              <img
                onClick={() => window.open(carouselData.discordLink, "_blank")}
                src={i1}
                alt="logo"
                className="icons_icons"
              />
            </Grid>
          ) : null}
          {carouselData?.twitterLink ? (
            <Grid item className="icons">
              <img
                onClick={() => window.open(carouselData.twitterLink, "_blank")}
                src={i5}
                alt="logo"
                className="icons_icons"
              />
            </Grid>
          ) : null}
          {carouselData?.instagramLink ? (
            <Grid item className="icons">
              <img
                onClick={() =>
                  window.open(carouselData.instagramLink, "_blank")
                }
                src={i2}
                alt="logo"
                className="icons_icons"
              />
            </Grid>
          ) : null}
          {carouselData?.redditLink ? (
            <Grid item className="icons">
              <img
                onClick={() => window.open(carouselData.redditLink, "_blank")}
                src={i3}
                alt="logo"
                className="icons_icons"
              />
            </Grid>
          ) : null}
          {carouselData?.tiktokLink ? (
            <Grid item className="icons">
              <img
                onClick={() => window.open(carouselData.tiktokLink, "_blank")}
                src={i4}
                alt="logo"
                className="icons_icons"
              />
            </Grid>
          ) : null}
          {carouselData?.twitchLink ? (
            <Grid item className="icons">
              <img
                onClick={() => window.open(carouselData.twitchLink, "_blank")}
                src={i6}
                alt="logo"
                className="icons_icons"
              />
            </Grid>
          ) : null}
          {carouselData?.youtubeLink ? (
            <Grid item className="icons">
              <img
                onClick={() => window.open(carouselData.youtubeLink, "_blank")}
                src={i7}
                alt="logo"
                className="icons_icons"
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid item>
          <Typography className="terms">
            {" "}
            © 2021 Nifty Ape Nation Powered by <a style={{ color: "#fff" }} href="https://www.dnablock.com/" target="_blank">DNABLOCK</a>. All rights reserved.
            <br></br>
            <span style={{cursor:"pointer"}} onClick={() => setTOpen(true)}>Terms and Conditions ·</span> Privacy Policy
          </Typography>
        </Grid>
      </Grid>

      
      <Grid container justify="center">
        <Grid item xs={12}>
          <Modal open={open} videoURL={videoURL} setOpen={setOpen} />
        </Grid>
      </Grid>
      {/* <Grid container justify="center">
        <Grid item xs={12}> */}
          <TermAndConditionModal open={Topen} setOpen={setTOpen} />
        {/* </Grid>
      </Grid> */}
    </Grid>
  );
}

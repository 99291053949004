import React, { useState } from "react";
import { Container, Grid, Typography, Component } from "@material-ui/core";
import "./Modal.css";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { Button } from "@material-ui/core";
import c1 from "../../Images/c1.png";
import { useSpring, animated } from "react-spring";
import eth from "../../Images/eth.png";
import X from "../../Images/X.png";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    background: "rgba(255, 255, 255, 0.9)",
    backgroundColor: "black",
    boxShadow: "0px 4px 50px rgba(0, 0, 0, 0.34)",
    borderRadius: "20px",
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    width: "750px",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
  titleP: {
    color: "white",
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function Portfollio(props) {
  const { open, slickData, setOpen } = props;
  console.log("slickD:", slickData);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const setting = {
    className: "center",
    // centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div
            style={{
              float: "right",
              padding: "10px",
              position: "absolute",
              right: 0,
            }}
          >
            <img
              src={X}
              height="20px"
              style={{ cursor: "pointer" }}
              onClick={() => setOpen(false)}
            />
          </div>
          <Grid container justify="space-between">
            <Grid item xs={12} md={8} lg={8}>
              <div style={{ justifyContent: "center", display: "flex" }}>
                <div>
                  {" "}
                  <img
                    src={slickData.image_url}
                    className="slick2Img"
                    style={{ marginTop: "10px", borderRadius: "5px" }}
                    height="500px"
                    width="100%"
                  />
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              style={{
                marginTop: "15px",
                padding: "15px",
              }}
            >
              <div>
                {/* <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography className="modal_typo1">
                    {slickData.hash}
                  </Typography>
                  <img
                    src={X}
                    height="20px"
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpen(false)}
                  />
                </div> */}
                <Typography className="modal_typo2">
                  {slickData.title}
                </Typography>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  {slickData?.sell_orders ? (
                    <>
                      <div>
                        <img src={eth} className="eth" />
                      </div>
                      <div>
                        <Typography style={{ textAlign: "right" }}>
                          <span className="modal_typo3">
                            {" "}
                            {Math.floor(
                              slickData.sell_orders[0].current_price *
                                Math.pow(10, -18)
                            )}
                          </span>{" "}
                          <br></br>{" "}
                          <span style={{ color: "white" }}>Average Price</span>
                        </Typography>
                      </div>
                    </>
                  ) : null}
                </div>
                <Typography className="modal_typo4">
                  Name: {slickData.name}
                </Typography>
                <Typography className="modal_typo5">
                  {slickData.description}
                </Typography>

                <div
                  style={{
                    textAlign: "center",
                    marginTop: "80px",
                  }}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    style={{
                      backgroundColor: "rgba(116, 23, 23, 0.872093)",
                      color: "white",
                      fontSize: "12px",
                      textAlign: "center",
                      height: "44px",
                      width: "120px",
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      window.open(slickData.permalink, "_blank");
                    }}
                  >
                    <b> Buy Now</b>
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
}

import React, { useEffect, useState } from "react";
import { Divider, Container, Grid } from "@material-ui/core";
import axios from "axios";
import NavBar from "../../Components/NavBar/NavBar";
import Main1 from "../../Components/HomePage/Main1/main1";
import Section2 from "../../Components/HomePage/Section2/Section2";
import Avatars from "../../Components/HomePage/3d_Avatars/Avatars";
import Member from "../../Components/HomePage/Members/Member";
import DNABlock from "../../Components/HomePage/DNA_Block/DNABlock";
import OpenOffers from "../../Components/HomePage/OpenOffers/OpenOffers";
import insta from "../../Images/insta.png";
import twitter from "../../Images/twitter.png";
import twitch from "../../Images/twitch.png";
import youtube from "../../Images/youtube.png";
import discord from "../../Images/discord.png";
import Roadmap from "../../Components/HomePage/Roadmap/Roadmap";
import Attributes from "../../Components/HomePage/Attributes/Attributes";
import Footer from "../../Components/Footer/Footer";
import Team from "../../Components/HomePage/Team/Team";
import card1 from "../../Images/c1.png";
import card2 from "../../Images/c2.png";
import card3 from "../../Images/c3.png";
import card4 from "../../Images/c4.png";
import t1 from "../../Images/t1.png";
import t2 from "../../Images/t2.png";
import t3 from "../../Images/t3.png";
import t4 from "../../Images/t4.png";
import t5 from "../../Images/t5.png";
import t6 from "../../Images/t6.png";
import t7 from "../../Images/t7.png";
import t8 from "../../Images/t8.png";
import i1 from "../../Images/i1.png";
import i2 from "../../Images/i2.png";
import i3 from "../../Images/i3.png";
import i4 from "../../Images/i4.png";
import i5 from "../../Images/i5.png";
import i6 from "../../Images/i6.png";
import i7 from "../../Images/i7.png";

function HomePage() {
  const team = [t1, t2, t3, t4, t5, t6, t7, t8];
  const icons = [i1, i2, i3, i4, i5, i6, i7];
  const cards = [
    {
      img: card1,
      title: "MYTHIC OG SILVERBACKS",
      des: "APE WORLD LEADERS",
      link: "https://dementclothing.com",
      hash: "#1005",
      price: "2.9",
      count: "200",
    },
    {
      img: card2,
      title: "OG  SILVERBACKS",
      des: "GUARDIANS OF THE APE NATION",
      link: "https://www.euphorya.co",
      hash: "#1004",
      count: "200",
      price: "0.5",
    },
    {
      img: card3,
      title: "SILVERBACKS",
      des: "LEADERS IN THE TRENCHES",
      link: "https://dementclothing.com",
      hash: "#1002",
      count: "250",
      price: "2.3",
    },
    {
      img: card4,
      title: "APE ARMY",
      des: "THE COLLECTIVE APE BRAIN",
      link: "https://dementclothing.com",
      hash: "#1003",
      count: "100",
      price: "1.8",
    },
    {
      img: card1,
      title: "TRADER APE",
      des: "TRADING STRATEGIES",
      link: "https://dementclothing.com",
      hash: "#1006",
      count: "55",
      price: "1.5",
    },
  ];
  const attCards = [
    {
      img: card1,
      title: "MYTHIC OG SILVERBACKS",
      des: "APE WORLD LEADERS",
      link: "https://dementclothing.com",
      hash: "#1005",
      price: "2.9",
      count: "200",
    },
    {
      img: card2,
      title: "OG  SILVERBACKS",
      des: "GUARDIANS OF THE APE NATION",
      link: "https://www.euphorya.co",
      hash: "#1004",
      count: "200",
      price: "0.5",
    },
    {
      img: card3,
      title: "SILVERBACKS",
      des: "LEADERS IN THE TRENCHES",
      link: "https://dementclothing.com",
      hash: "#1002",
      count: "250",
      price: "2.3",
    },
    {
      img: card4,
      title: "APE ARMY",
      des: "THE COLLECTIVE APE BRAIN",
      link: "https://dementclothing.com",
      hash: "#1003",
      count: "100",
      price: "1.8",
    },
    {
      img: card1,
      title: "TRADER APE",
      des: "TRADING STRATEGIES",
      link: "https://dementclothing.com",
      hash: "#1006",
      count: "55",
      price: "1.5",
    },
    {
      img: card1,
      title: "TRADER APE",
      des: "TRADING STRATEGIES",
      link: "https://dementclothing.com",
      hash: "#1006",
      count: "55",
      price: "1.5",
    },
  ];

  const [contentData, setContentData] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/get-content`)
      .then((res) => {
        console.log("res.data", res.data);
        setContentData(res.data);
      })
      .catch((err) => console.log("error", err));
  }, []);

  console.log("contentData", contentData);

  return (
    <>
      <div className="main">
        <Container className="container_width">
          <NavBar />
         
          <Main1 icons={icons} carouselData={contentData?.carouselData[0]} />
          <Section2 cards={cards} toaData={contentData?.toaData} />
          <div className="attributes_main22">
            <Avatars avatarData={contentData?.introductionData[0]} />
          </div>
          <div>
            <Divider className="divider" />
          </div>
          {/* <div id="MemberBenefits">
            <Member memberData={contentData?.collectorData[0]} />
          </div> */}
          <div id="/Roadmap">
            <Roadmap roadmapData={contentData?.roadmapData} />
          </div>
          <div id="/Attributes">
            <div>
              <Team
                team={team}
                icons={icons}
                teamData={contentData?.teamData[0]}
                teamCardData={contentData?.teamCardData}
              />
            </div>
          </div>
          <div>
            <Divider className="divider" />
          </div>

          {/* <div id="/DNABlock">
            <DNABlock />
          </div> */}
          {/* <OpenOffers openOfferData={contentData?.openOfferData[0]} /> */}
        </Container>
        <Footer icons={icons} carouselData={contentData?.carouselData[0]} />
      </div>
    </>
  );
}

export default HomePage;

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import TeamCard from "./TeamCard/TeamCard";
import "./Team.css";
import {
  Container,
  Grid,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";
// import Card from './AttributeCard/Card';
// import './Attributes.css';
import Modal from "../../Modal/Modal";

export default function Attributes({ team, icons, teamData, teamCardData }) {
  const [slickData, setSlickData] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleOpen = (data) => {
    console.log("data:", data);
    setSlickData(data);
    setOpen(true);
  };
  const history = useHistory();

  function meetApes() {
    history.push(`/Collections`);
  }

  return (
    <>
      <Container className="attributes_main_team">
        <Grid container justify="center">
          <Grid item container xs={12}>
            <Typography className="att_typo1_team">
              <span className="att_typo2"> {teamData?.title} </span> <br></br>
              {teamData?.desciption}
            </Typography>
          </Grid>
          <Grid container style={{ marginTop: "50px" }}>
            {teamCardData?.map((i) => (
              <Grid item xs={6} md={6} lg={4}>
                <TeamCard background={i} icons={icons} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Modal open={open} slickData={slickData} setOpen={setOpen} />
      </Container>
    </>
  );
}

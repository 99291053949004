import React, { useState } from "react";
import axios from "axios";
import { Container, Grid, Typography, Divider } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ApeCard from "./ApeCard/ApeCard";
import "./MeetApes.css";
import slick11 from "../../../Images/slick11.png";
import slick12 from "../../../Images/slick22.png";
import Modal from "../../HomePage/Section2/Modal/Modal";
import Fcard from "../../F_card/f_card";
import Card from "../../HomePage/Attributes/AttributeCard/Card";
export default function MeetApes({ apeCard, attCards, textData, toa }) {
  const [slickData, setSlickData] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleOpen = (data) => {
    console.log("data:", data);
    setSlickData(data);
    setOpen(true);
  };

  const settings = {
    dots: false,
    infinite: true,

    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <img src={slick12} />,
    prevArrow: <img src={slick11} />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  console.log("toa in meetapes", toa);

  return (
    <Container className="attributes_main ">
      <Grid
        container
        justify="space-between"
        className="margin_top"
        spacing={2}
      >
        <Grid item xs={12} md={3} lg={3} className="meet_card">
          <Typography className="meet_typo">{textData?.title}</Typography>
          <Divider className="meet_Divider" />
          <Typography className="meet_typo2">
            <b> {textData?.subTitle}</b>: {textData?.description}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={9} className="margin_top">
          <div>
            <Slider {...settings}>
              {toa?.map((data) => (
                <div onClick={() => handleOpen(data)} className="slick_div">
                  {" "}
                  <ApeCard toa={data} />
                  {/* <Fcard /> */}
                </div>
              ))}
            </Slider>
          </div>
        </Grid>
      </Grid>
      {/* <Grid
        container
        justifyContent="space-around"
        xs={12}
        spacing={2}
        style={{ marginTop: "50px" }}
      >
        {attCards?.map((i) => (
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            style={{ marginTop: "20px", cursor: "pointer" }}
            // onClick={() => handleOpen(i)}
          >
            <Card attCards={i} />
          </Grid>
        ))}
      </Grid> */}
      {/* <Divider className="divider" /> */}
      <Modal open={open} slickData={slickData} setOpen={setOpen} />
    </Container>
  );
}
